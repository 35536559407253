<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12">
          
              <div class="card" v-if="$gate.isAdmin()">
                <div class="card-header">
                  <h3 class="card-title">Listado de documentos</h3>
  
                  <div class="card-tools">
                    
                    <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                        <i class="fa fa-plus-square"></i>
                        Agregar
                    </button>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Proceso</th>
                        <th>Tipo Documento</th>
                        <th>Nombre</th>
                        <th>Link</th>
                        <th>Observaciones</th>
                        <th>Activo</th>
                        <!--<th>Creado</th>-->
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr v-for="document in documentes.data" :key="document.id">
  
                        <td>{{document.id}}</td>
                        <td class="text-capitalize">{{document.proceso.titleprocess}}</td>
                        <td class="text-capitalize">{{document.caracterizacion.name}}</td>
                        <td class="text-capitalize">{{document.titledocumento}}</td>
                        <td>{{document.link}}</td>
                        <td>{{document.observaciones}}</td>
                        <td>{{document.active}}</td>
                        <!--<td>{{document.created_at}}</td>-->
                        <td>
  
                          <a href="#" @click="editModal(document)">
                              <i class="fa fa-edit blue"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <pagination :data="documentes" @pagination-change-page="getResults"></pagination>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
  
  
          <div v-if="!$gate.isAdmin()">
              <not-found></not-found>
          </div>
  
          <!-- Modal -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
              <div class="modal-dialog modal-xl" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Crear nuevo documento</h5>
                      <h5 class="modal-title" v-show="editmode">Actualizar Documento</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <!-- <form @submit.prevent="createUser"> -->
  
                  <form @submit.prevent="editmode ? updateDocumento() : createDocumento()">
                      <div class="modal-body">
                           <div class="form-group">
                              <label>Tipo documento</label>
                              <select name="type" v-model="form.caracterizacion_id" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('caracterizacion_id') }">
                                <option value="">Tipo Documento</option>
                                <option v-for="tipo in caracterizacion" :key="tipo.id" :value="tipo.id" v-text="tipo.name"></option>
                            </select>
                            <has-error :form="form" field="caracterizacion_id"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Proceso</label>

                              <div class="col-12">
                                <div class="row" v-if="!editmode">
                                    <div class="col-6" v-for="proce in process" :key="proce.id">
                                        <input type="checkbox" id="proceso_id" name="proceso_id" :value="proce.id" v-model="form.proceso" :class="{ 'is-invalid': form.errors.has('proceso') }">
                                        <label :for="proce.titleprocess">{{proce.titleprocess}}</label>
                                    </div>                
                                    <has-error :form="form" field="proceso"></has-error>                
                                </div>
                                
                                <div class="row" v-else>
                                    <select name="type" v-model="form.process_id" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('proceso') }">
                                        <option value="">Proceso</option>
                                        <option v-for="proce in process" :key="proce.id" :value="proce.id" v-text="proce.titleprocess" ></option>
                                    </select>
                                </div>
                                    
                             </div>
                          </div>
                          <div class="form-group">
                              <label>Nombre</label>
                              <input v-model="form.titledocumento" type="text" name="titledocumento"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('titledocumento') }">
                              <has-error :form="form" field="titledocumento"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Link</label>
                              <input v-model="form.link" type="text" name="link"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('link') }">
                              <has-error :form="form" field="link"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Observaciones</label>
                              <textarea v-model="form.observaciones"  class="form-control" :class="{ 'is-invalid': form.errors.has('observaciones') }"></textarea>
                              <!--<input v-model="form.titleprocess" type="text" name="titleprocess"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('titleprocess') }">-->
                              <has-error :form="form" field="observaciones"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Activo</label>
                              <select name="type" v-model="form.active" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('active') }">
                                <option value="">Seleccionar</option>
                                <option value="1">Activo</option>
                                <option value="0">Inactivo</option>
                            </select>
                          </div>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                          <button v-show="editmode" type="submit" class="btn btn-success">Actualizar</button>
                          <button v-show="!editmode" type="submit" class="btn btn-primary">Crear</button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </template>
  
  <script>
      export default {
          data () {
              return {
                  editmode: false,
                  documentes : {},
                  process:{},
                  caracterizacion: {},
                  proceso_id: [],
                  form: new Form({
                      id : '',
                      titledocumento: '',
                      link: '',
                      caracterizacion_id: '',
                      proceso: [],
                      observaciones: '',
                      active: 1,
                      process_id: '',
                  })
              }
          },
          methods: {
            getProcess() {  
                this.$Progress.start();                
                axios.get('/api/process/list').then(({ data }) => (this.process = data.data));
                this.$Progress.finish();
            },
            getCaracterizacion() {  
                this.$Progress.start();                
                axios.get('/api/category/list').then(({ data }) => (this.caracterizacion = data.data));
                this.$Progress.finish();
            }, 
            getResults(page = 1) {  
                    this.$Progress.start();                    
                    axios.get('/api/document?page=' + page).then(({ data }) => (this.documentes = data.data));  
                    this.$Progress.finish();
            },
            updateDocumento(){
                this.$Progress.start();
                this.form.put('/api/document/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                    icon: 'success',
                    title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadDocuments();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
              editModal(process){
                  this.editmode = true;
                  this.form.reset();
                  $('#addNew').modal('show');
                  console.log(process)
                  this.form.fill(process);
              },
              newModal(){
                  this.editmode = false;
                  this.form.reset();
                  $('#addNew').modal('show');
              },  
              loadDocuments(){
                  if(this.$gate.isAdmin()){
                      axios.get("/api/document").then(({ data }) => (this.documentes = data.data));
                  }
              },              
              createDocumento(){                  
                  this.form.post('/api/document')
                  .then((response)=>{
                    
                    if(response.data.message=="Error"){
                        Toast.fire({
                        icon: 'error',
                        title: 'Debes seleccionar al menos un proceso'
                        });
                    }else{
                        $('#addNew').modal('hide');  
                        Toast.fire({
                                icon: 'success',
                                title: response.data.message
                        });  
                        this.$Progress.finish();
                        this.loadDocuments();
                    }
                      
                  })
                  .catch(()=>{
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
              }
  
          },
          mounted() {
              //console.log('Component mounted.')
          },
          created() {  
              this.$Progress.start();
              this.loadDocuments();
              this.getCaracterizacion();
              this.getProcess();
              this.$Progress.finish();
          }
      }
  </script>
  