<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12">
          
              <div class="card" v-if="$gate.isAdmin()">
                <div class="card-header">
                  <h3 class="card-title">Listado de procesos</h3>
  
                  <div class="card-tools">
                    
                    <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                        <i class="fa fa-plus-square"></i>
                        Agregar
                    </button>
                  </div>
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Objetivo</th>
                        <th>Nivel Prioridad</th>
                        <th>Activo</th>
                        <!--<th>Creado</th>-->
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                       <tr v-for="process in processes.data" :key="process.id">
  
                        <td>{{process.id}}</td>
                        <td class="text-capitalize">{{process.titleprocess}}</td>
                        <td>{{process.objetivo}}</td>
                        <td>{{process.nivelprioridad}}</td>
                        <td>{{process.active}}</td>
                        <!--<td>{{process.created_at}}</td>-->
                        <td>
  
                          <a href="#" @click="editModal(process)">
                              <i class="fa fa-edit blue"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    <pagination :data="processes" @pagination-change-page="getResults"></pagination>
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
  
  
          <div v-if="!$gate.isAdmin()">
              <not-found></not-found>
          </div>
  
          <!-- Modal -->
          <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
              <div class="modal-dialog modal-lg" role="document">
                  <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Crear nuevo proceso</h5>
                      <h5 class="modal-title" v-show="editmode">Actualizar Proceso</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
  
                  <!-- <form @submit.prevent="createUser"> -->
  
                  <form @submit.prevent="editmode ? updateProcess() : createProcess()">
                      <div class="modal-body">
                          <div class="form-group">
                              <label>Nombre proceso</label>
                              <input v-model="form.titleprocess" type="text" name="titleprocess"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('titleprocess') }">
                              <has-error :form="form" field="titleprocess"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Objetivo</label>
                              <textarea v-model="form.objetivo"  class="form-control" :class="{ 'is-invalid': form.errors.has('objetivo') }"></textarea>
                              <!--<input v-model="form.titleprocess" type="text" name="titleprocess"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('titleprocess') }">-->
                              <has-error :form="form" field="objetivo"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Nivel de prioridad</label>
                              <select name="type" v-model="form.nivelprioridad" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('nivelprioridad') }">
                                <option value="">Nivel de prioridad</option>
                                <option value="Alta">Alta</option>
                                <option value="Media">Media</option>
                                <option value="Baja">Baja</option>
                            </select>
                          </div>
                          <div class="form-group">
                              <label>Nota</label>
                              <textarea v-model="form.nota"  class="form-control" :class="{ 'is-invalid': form.errors.has('nota') }"></textarea>
                              <!--<input v-model="form.titleprocess" type="text" name="titleprocess"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('titleprocess') }">-->
                              <has-error :form="form" field="nota"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Activo</label>
                              <select name="type" v-model="form.active" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('active') }">
                                <option value="">Seleccionar</option>
                                <option value="1">Activo</option>
                                <option value="0">Inactivo</option>
                            </select>
                          </div>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                          <button v-show="editmode" type="submit" class="btn btn-success">Actualizar</button>
                          <button v-show="!editmode" type="submit" class="btn btn-primary">Crear</button>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    </section>
  </template>
  
  <script>
      export default {
          data () {
              return {
                  editmode: false,
                  processes : {},
                  form: new Form({
                      id : '',
                      titleprocess: '',
                      objetivo: '',
                      nivelprioridad: '',
                      nota: '',
                      active: 1,
                  })
              }
          },
          methods: {
  
              getResults(page = 1) {
  
                    this.$Progress.start();
                    
                    axios.get('/api/process?page=' + page).then(({ data }) => (this.processes = data.data));
  
                    this.$Progress.finish();
              },
              updateProcess(){
                  this.$Progress.start();
                  this.form.put('/api/process/'+this.form.id)
                  .then((response) => {
                      // success
                      $('#addNew').modal('hide');
                      Toast.fire({
                        icon: 'success',
                        title: response.data.message
                      });
                      this.$Progress.finish();
                          //  Fire.$emit('AfterCreate');
  
                      this.loadProcesses();
                  })
                  .catch(() => {
                      this.$Progress.fail();
                  });
  
              },
              editModal(process){
                  this.editmode = true;
                  this.form.reset();
                  $('#addNew').modal('show');
                  this.form.fill(process);
              },
              newModal(){
                  this.editmode = false;
                  this.form.reset();
                  $('#addNew').modal('show');
              },
  
              loadProcesses(){
                  if(this.$gate.isAdmin()){
                      axios.get("/api/process").then(({ data }) => (this.processes = data.data));
                  }
              },
              
              createProcess(){
  
                  this.form.post('/api/process')
                  .then((response)=>{
                      $('#addNew').modal('hide');
  
                      Toast.fire({
                              icon: 'success',
                              title: response.data.message
                      });
  
                      this.$Progress.finish();
                      this.loadProcesses();
                  })
                  .catch(()=>{
                      Toast.fire({
                          icon: 'error',
                          title: 'Some error occured! Please try again'
                      });
                  })
              }
  
          },
          mounted() {
              //console.log('Component mounted.')
          },
          created() {  
              this.$Progress.start();
              this.loadProcesses();
              this.$Progress.finish();
          }
      }
  </script>
  