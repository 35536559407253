<template>
    <section class="content">
      <div class="container-fluid">
          <div class="row">
  
            <div class="col-12">
          
              <div class="card" v-if="$gate.isAdmin() || $gate.isUser()">
                <div class="card-header">
                  <h3 class="card-title">{{title}}</h3>
  
                  <!--<div class="card-tools">
                    
                    <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                        <i class="fa fa-plus-square"></i>
                        Agregar
                    </button>
                  </div>-->
                </div>
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                  <div class="container-fluid">
                      
                      <div class="col-md-12">
                        <div class="aboutus-area pt-5">
                            <div class="container">
                                <div class="row">
                                <div class="col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="alert alert-warning alert-dismissible fade show">
                                            <p><strong>ESTOS DOCUMENTOS PUEDEN MEJORARSE</strong></p>
                                            <p>Si consideras que la información de cualquiera de estos documentos puede mejorarse, agradecemos tu ayuda. Para conocer como hacer los ajustes por favor haz clic en el siguiente enlace del Sistema de Solicitudes (Clic Aquí) o comunícate con el área de Gestión de la Calidad.</p>
                                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="aboutus-area pt-2">
                            <div class="container">
                                <div class="row">
                                <div class="col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="aboutus-content ">
                                            <h3>Objetivo
                                            </h3>
                                            
                                            <p class="pt-2">{{objetivo}}</p>
                                                                                            
                                            
                                        </div>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>
                        <div class="aboutus-area pt-2">
                            <div class="container">
                                <div class="row">
                                <div class="col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="aboutus-content ">
                                            <h3>En tu proceso esta pendiente
                                            </h3>
                                                <div class="alert alert-success" role="alert" v-if="prioridad=='Baja'">
                                                    {{prioridad}}
                                                </div>
                                                <div class="alert alert-warning" role="alert" v-if="prioridad=='Media'">
                                                    {{prioridad}}
                                                </div>
                                                <div class="alert alert-danger" role="alert" v-if="prioridad=='Alta'">
                                                    {{prioridad}}
                                                </div>
                                        </div>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="aboutus-area pt-1">
                            <div class="container">
                                <div class="row">
                                <div class="col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="nota-about">
                                            
                                            
                                            Recuerda que el proceso de Mejora continua esta presto a atender sus solicitudes y acompañarles en el cumplimiento de las mismas.
                                                (Solicitar acompañamiento ingresando al link de Ticket's)
                                            
                                                                                            
                                            
                                        </div>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr>
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-4" v-for="valores in categorias" :key="valores.id">
                                    
                                    <div class="card text-center">
                                        <span class="text-center">
                                    <img class="img-responsive pt-2" :src="'/images/'+valores.icon" :alt="valores.name" width="50%">
                                </span>
                                    <div class="card-body">
                                        <h5 class="text-center titulocar">{{valores.name}}</h5>
                                        <p class="card-text">
                                            <span v-for="doc in documentos" :key="doc.id">
                                                <span v-if="doc.caracterizacion_id==valores.id">
                                                    <a :href="doc.link" target="_blank" class="enlacedocumento">
                                                    {{doc.titledocumento.toUpperCase()}}</a>
                                                    <br>
                                                </span>
                                            </span>
                                        </p>
                                        
                                    </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                        <hr>
                        <div class="aboutus-area pt-1">
                            <div class="container">
                                <div class="row">
                                <div class="col-xs-12">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="aboutus-content">
                                            
                                            
                                            Si usted copia o imprime alguna caracterización, procedimiento, guía o instructivo, estos documentos el sistema de gestión de Edeso lo considera No controlado y no se hace responsable por su consulta o uso. Si desea consultar la versión actualizada y controlada de estos documentos, consulte siempre el sitio web con la siguiente ruta de acceso: 
                                            www.edeso.gov.co > transparencia > nosotros > modelo integrado de planeación y gestión > sgc
                                            
                                                                                            
                                            
                                        </div>
                                    </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      


                          
                      </div>
                  </div>
                </div>
                <!-- /.card-body -->
                <div class="card-footer">
                    
                </div>
              </div>
              <!-- /.card -->
            </div>
          </div>
  
  
          <!--<div v-if="!$gate.isAdmin()  || !$gate.isUser()">
              <not-found></not-found>
          </div>-->
  
      </div>
    </section>
  </template>
  <style>
        .enlacedocumento {
            font-size: 13px;
            color: rgb(190, 22, 54);
        }
        .titulocar {
            font-weight: bold;
        }
        .nota-about {
        border: 1px solid #f00;
        padding: 10px;
        }
        .aboutus-area {
            padding-top:10px;
            padding-bottom:10px;
        }
        /*-- aboutus Image --*/
        .aboutus-image {
        margin: 0px;
        width: 480px; 
            height:500px;
        }
        .aboutus-image img {
            height:100%;
            width: 100%; }

        /*-- aboutus Content --*/
        .aboutus-content h2 {
        font-size: 40px;
        font-weight: 800;
        line-height: 40px;
        margin-bottom: 2px; }

        .aboutus-content h2 span {
            color: #71b100; }

        .aboutus-content h4 {
        font-size: 18px;
        font-weight: 500;
        color: #9b9b9b;
        margin-bottom: 23px; }

        .aboutus-content p {
        font-size: 16px;
        line-height: 27px; }
        
        /*-- counter --*/
        .single-counter {
        border:none;
        margin-top: 32px;
        float: left;
        width: 100%; }
        .single-counter {
            float: left;
            width: 25%;
            padding: 28px 15px 28px; }
        
            h4{text-align:center;margin:30px 0px;color:#444;}
        .mt-10{margin-top:20px;}
        a:hover,a:focus{
            text-decoration: none;
            outline: none;
        }
        #accordion .panel{
            border: none;
            border-radius: 3px;
            box-shadow: none;
            margin-bottom: 15px;
        }
        #accordion .panel-heading{
            padding: 0;
            border: none;
            border-radius: 3px;
        }
        #accordion .panel-title a{
            display: block;
            padding: 12px 15px;
            background: #fff;
            font-size: 18px;
            font-weight: 400;
            color: #f81ac1;
            /*border: 1px solid #ececec;*/
            box-shadow: 0 0 10px rgba(0,0,0,.05);
            position: relative;
            transition: all 0.5s ease 0s;
            box-shadow: 0 1px 2px rgba(43,59,93,0.30);
        }
        #accordion .panel-title a.collapsed{
            box-shadow: none;
            color: #676767;
            box-shadow: 0 1px 2px rgba(43,59,93,0.30);
        }
        #accordion .panel-title a:before,
        #accordion .panel-title a.collapsed:before{
            content: "\f067";
            font-family: "Font Awesome 5 Free";
            width: 25px;
            height: 25px;
            line-height: 28px;
            font-size: 15px;
            font-weight: 900;
            color: #f81ac1;
            text-align: center;
            position: absolute;
            top: 8px;
            right: 15px;
            transform: rotate(135deg);
            transition: all 0.3s ease 0s;
        }
        #accordion .panel-title a.collapsed:before{
            color: #676767;
            transform: rotate(0);
        }
        #accordion .panel-title a:after{
            content: "";
            width: 1px;
            height: 100%;
            background: #ececec;
            position: absolute;
            top: 0;
            right: 55px;
        }
        #accordion .panel-body{
            padding: 0px 15px;
            border: none;
            font-size: 15px;
            color: #615f5f;
            line-height: 27px;
        }
  </style>
  <script>
      export default {
        props:['id'],
          data () {
              return {
                  processes : [],
                  title: '',
                  objetivo: '',
                  prioridad: '',
                  categorias: [],
                  documentos: [],
              }
          },
          methods: {  
              loadProceso(){
                let me = this;     
                  if(this.$gate.isAdmin() || this.$gate.isUser()){

                    axios.get('/api/proceso/'+this.id).then(function (response) {
                     console.log(response.data.documento)
                     me.processes = response.data.proceso;
                     me.title = response.data.proceso.titleprocess;
                     me.objetivo = response.data.proceso.objetivo;
                     me.prioridad = response.data.proceso.nivelprioridad;
                    me.documentos = response.data.documento;
                     me.categorias = response.data.categoria
                    }).catch(function (error) {
                        console.log(error);
                    });
                      //axios.get("/api/proceso/"+this.id).then(({ data }) => (this.processes = data.data));
                  }
              },
  
          },
          mounted() {
              //console.log('Component mounted.')
          },
          created() {  
              this.$Progress.start();
              
              this.loadProceso();
              this.$Progress.finish();
          }
      }
  </script>
  