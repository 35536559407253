export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },
    { path: '/documents', component: require('./components/Documents.vue').default },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/category', component: require('./components/product/Category.vue').default },
    { path: '/process', component: require('./components/Process.vue').default },
    //{ path: '/proceso', component: require('./components/Proceso.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
